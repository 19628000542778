import React, { FC } from 'react';

import PopularProductItem from 'components/common/PopularProductItem';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { IRecommendedProducts } from './models';
import './RecommendedProducts.scss';

const RecommendedProducts: FC<IRecommendedProducts> = ({ startText, endText, productList }) => (
  <div className="dt-recommended">
    <DangerouslySetInnerHtml html={startText} className="dt-recommended-start" />
    <div className="dt-recommended__items">
      {productList.map((item) => (
        <PopularProductItem key={item.id} product={item} isPromo />
      ))}
    </div>
    <DangerouslySetInnerHtml html={endText} className="dt-recommended-end" />
  </div>
);

export default RecommendedProducts;
